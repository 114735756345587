.statek {
	background-image: url(../../assets/wodor.jpeg);
	height: 300px;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}
.text {
	padding: 40px;
}
