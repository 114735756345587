.home-opener {
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100%;
	position: relative;
	color: #fff;

	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}

	.locus {
		width: 340px;
		text-align: center;
		position: absolute;
		padding-top: 20px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 5;
		@media only screen and (max-width: 600px) {
			width: 150px;
		}
	}

	.half-screen {
		flex: 1;
		text-align: center;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		&-hover {
			position: absolute;
			top: 0;
			left: 0;
			background: #0e012a;
			width: 100%;
			height: 100%;
			opacity: 0.65;
			-webkit-transition: 1s;
			-o-transition: 1s;
			transition: 1s;

			&:hover {
				opacity: 0;
			}
		}

		&.statek {
			background-image: url("/assets/statek.jpeg");
		}
		&.wiatrak {
			background-image: url("/assets/wodor.jpeg");
		}
		div {
			display: flex;
			text-align: center;
			justify-content: center;
			height: 100%;
			align-items: center;
			z-index: 1;
			@media only screen and (max-width: 600px) {
				font-size: 2.5rem;
			}
		}
	}
}
