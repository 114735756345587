.barka {
	background-image: url(../../assets/barka.jpeg);
	height: 400px;
	background-position: bottom;
	background-size: cover;
	overflow: hidden;
}
.text {
	padding: 40px;
}

.link {
	color: inherit;
	text-decoration: none;
	font-size: 1.6rem;
}
