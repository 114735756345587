.wodor {
	background-image: url(../../assets/wodor.jpeg);
	height: 300px;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}
.text {
	padding: 40px;
}
