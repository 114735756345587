.morska {
	background-image: url(../../assets/morska.jpeg);
	height: 400px;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}
.text {
    padding: 40px;
}

.link {
	color: inherit;
	text-decoration: none;
	font-size: 1.6rem;
}