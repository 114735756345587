.under-construction {
	width: 70%;
    margin: auto;
    text-align: center;
	@media only screen and (max-width: 600px) {
		width: 70%;
	}
}
p {
	font-size: 0.8rem;
}
