.statek {
	background-image: url(../../assets/statek.jpeg);
	height: 400px;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}
.contact-form {
	padding: 30px;
	.MuiFormControl-root {
		margin-bottom: 10px;
	}
}