.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 90px;
	align-items: center;
	.logo {
		display: flex;
		flex-direction: row;
		img {
			max-width: 100px;
		}
		&-subclass {
			color: #0e012a;
			font-weight: 900;
			display: flex;
			align-items: flex-end;
			margin-left: -2px;
			margin-bottom: -6px;
			font-size: 22px;
			font-style: italic;
		}
	}
	.links {
		display: flex;
		flex-direction: row;
		div {
			padding-right: 30px;
		}
		.link {
			a {
				color: inherit;
				text-decoration: none;
				font-size: 1.15rem;
			}
		}
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.links-mobile,
	.language-switch-mobile {
		display: none;
		@media only screen and (max-width: 600px) {
			display: flex;
		}
	}

	.flex {
		display: flex;
	}

	.language-switch {
		display: flex;
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}

	.language-switch {
		button {
			background-color: transparent;
			border: 0;
			border-bottom: 1px solid;
			text-transform: capitalize;
			padding: 5px;
			margin: 0 5px 0 5px;
		}
	}

	.div {
		margin-bottom: 0;
	}
}
.MuiDrawer-paper {
	width: 45%;
	.links {
		padding: 20px 10px;

		.link {
			a {
				color: inherit;
				text-decoration: none;
				font-size: 1.15rem;
			}
		}
		.language-switch-mobile {
			button {
				background-color: transparent;
				border: 0;
				border-bottom: 1px solid;
				text-transform: capitalize;
				padding: 5px;
				margin: 0 5px 0 5px;
			}
		}
	}
}
